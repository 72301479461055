<template>
  <v-overlay>
    <VProgressCircular indeterminate size="64"/>
  </v-overlay>
</template>

<script>
export default {
  name: 'Login',
  created() {
    location.href = `${process.env.VUE_APP_ROOT_API}/auth/oauth/hexspoor`;
  },
};
</script>

<style scoped>

</style>
